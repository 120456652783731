import * as React from 'react';
import { Box, Button, Card, CardActions, CardContent, CardHeader, FormControl, FormHelperText, Modal, OutlinedInput, TextField, Toolbar, Typography, useFormControl } from "@mui/material";
import { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { apiUrl } from "../constant/url";
import { useForm } from 'react-hook-form';

// const formSchema = object({
//     name: string()
//       .nonempty('Name is required')
//       .max(32, 'Name must be less than 100 characters'),
//     email: string().nonempty('Email is required').email('Email is invalid'),
//     password: string()
//       .nonempty('Password is required')
//       .min(8, 'Password must be more than 8 characters')
//       .max(32, 'Password must be less than 32 characters'),
//     passwordConfirm: string().nonempty('Please confirm your password'),
//     terms: literal(true, {
//       invalid_type_error: 'Accept Terms is required',
//     }),
//   }).refine((data) => data.password === data.passwordConfirm, {
//     path: ['passwordConfirm'],
//     message: 'Passwords do not match',
//   });
  
//   type formInput = TypeOf<typeof formschema>;

export default function Editor() {
    let navigate = useNavigate();
    // let [addBook, book] = useOutletContext();

    const { register, handleSubmit, watch, formState: { errors } } = useForm();

    const createData = async (data) => {
        await fetch(apiUrl.epos+'/new-client', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data),
        });

        navigate(-1);
    }

    // Create or update book
    // const addNew = async (values) => {
    //     console.log(values);
        
    //     let data = {
    //         user_name: event.target.user.value,
    //         email: event.target.email.value,
    //         password: event.target.password.value,
    //         shop_name: event.target.shop_name.value,
    //         address: event.target.address.value,
    //         employees: event.target.employees.value,
    //         phone_one: event.target.phone.value,
    //     }
        
    //     await createData(data);
    // }

    return(
        <Fragment>
            <Toolbar></Toolbar>
            <Box sx={{ maxWidth: '40rem', margin: 'auto' }}>
                <Typography variant='h5' component='h1' sx={{ mb: '2rem', mt: '2rem' }}>
                    Create Client
                </Typography>
                <Box
                    component='form'
                    noValidate
                    autoComplete='off'
                    onSubmit={handleSubmit(createData)}
                >
                    <TextField
                    sx={{ mb: 2 }}
                    label='User Name'
                    name='user_name'
                    fullWidth
                    required
                    {...register('user_name', {required: true})}
                    helperText={errors['user_name']? "User name is required": '' }
                    />

                    <TextField
                    sx={{ mb: 2 }}
                    label='Email'
                    fullWidth
                    required
                    name='email'
                    type='email'
                    {...register('email', {required: true})}
                    helperText={errors['email']? "Email is required": "" }
                    />

                    <TextField
                    sx={{ mb: 2 }}
                    label='Password'
                    fullWidth
                    required
                    name='password'
                    type='password'
                    {...register('password', {required: true})}
                    helperText={errors['password']? "Password is required": ""}
                    />

                    {/* <TextField
                    sx={{ mb: 2 }}
                    label='Confirm Password'
                    fullWidth
                    required
                    name='password'
                    type='password'
                    /> */}

                    <TextField
                    sx={{ mb: 2 }}
                    label='Shop Name'
                    fullWidth
                    required
                    name='shop_name'
                    {...register('shop_name', {required: true})}
                    helperText={errors['shop_name']? "Shop name is required": ""}
                    />

                    <TextField
                    sx={{ mb: 2 }}
                    label='Address'
                    fullWidth
                    required
                    name='address'
                    {...register('address', {required: true})}
                    helperText={errors['address']? "Address is required": ""}
                    />

                    <TextField
                    sx={{ mb: 2 }}
                    label='Employees'
                    fullWidth
                    required
                    name='employees'
                    {...register('employees', {required: true})}
                    helperText={errors['employees']? "Employees is required": ""}
                    />

                    <TextField
                    sx={{ mb: 2 }}
                    label='Phone Number'
                    fullWidth
                    required
                    name='phone_one'
                    {...register('phone_one', {required: true})}
                    helperText={errors['phone_one']? "Phone number is required": ""}
                    />

                    <Button
                    variant='contained'
                    fullWidth
                    type='submit'
                    // loading={loading}
                    sx={{ py: '0.8rem', mt: '1rem' }}
                    >
                    Create
                    </Button>
                </Box>
                </Box>
        </Fragment>
    );
}