// import logo from './logo.svg';
import './App.css';
import MyAppBar from './pages/MyAppBar';
import { Container, Fab } from '@mui/material';
import EposTable from './pages/EposTable';
import {BrowserRouter as Router,  Link,  Navigate,  Route, Routes } from 'react-router-dom';
import { Add } from '@mui/icons-material';
import Editor from './components/Editor';

function App() {
  return (
    <div className="App">
      <Router>
        <MyAppBar/>
        <Container>
          <Routes>
            <Route exact path="/" element={<Navigate replace to="/epos" />} />
            <Route path='/epos' element={<EposTable/>} />
            <Route path='/epos-decimal' element={<EposTable/>} />
            <Route path='/create-client' element={<Editor />}/>
          </Routes>
          <Link to='/create-client'>
            <Fab color="primary" aria-label="add" sx={{position: 'fixed', bottom: '20px', right: '20px'}}>
                <Add/>
            </Fab>
          </Link>
        </Container>
      </Router>
    </div>
  );
}

export default App;
